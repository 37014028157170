import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ThemeContext from "../context/ThemeContext"

const AboutUsPage = data => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="" />
          <div className="">
            <section className={theme.dark ? "dark dark-5" : "light"}>
              <div className="container">
                <div classNameName="row">
                  <div className="col-md-3">
                    <div className="heading-text heading-section">
                      <h2>ABOUT TECHIMPACE</h2>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row ">
                      <div className="col-lg-6 text-large">
                        <div
                          className={theme.dark ? " text-light" : " text-dark"}
                        >
                          we are a leading technology solutions provider
                          dedicated to driving innovation and transforming
                          businesses across various industries. With our
                          expertise in cutting-edge technologies and a
                          passionate team of professionals, we deliver
                          comprehensive and customized solutions to meet the
                          evolving needs of our clients.
                        </div>
                      </div>
                      <div className="col-lg-6 text-large">
                        <div
                          className={theme.dark ? "text-light" : "text-dark"}
                        >
                          Our mission at TechImpace is to empower businesses by
                          leveraging technology to enhance their operational
                          efficiency, competitiveness, and growth. We strive to
                          be at the forefront of technological advancements,
                          offering transformative solutions that drive digital
                          transformation and enable organizations to thrive in a
                          rapidly changing digital landscape.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="box-fancy section-fullwidth text-light p-b-0 p-t-0">
              <div className="row">
                <div
                  className={theme.dark ? "col-lg-4 bg-d1" : "col-lg-4 bg-b1 "}
                >
                  <h1 className="text-lg text-uppercase">01.</h1>
                  <h3>Innovation</h3>
                  <span>
                    We foster a culture of innovation, encouraging our team
                    members to think creatively, explore new ideas, and push the
                    boundaries of technology. We believe in staying ahead of the
                    curve and continuously adapting to emerging trends
                  </span>
                </div>
                <div
                  className={
                    theme.dark ? "col-lg-4 bg-d2" : "col-lg-4 bg-b2 text-light"
                  }
                >
                  <h1 className="text-lg text-uppercase">02.</h1>
                  <h3>Excellence</h3>
                  <span>
                    We are committed to delivering excellence in everything we
                    do. From conceptualization to execution, we maintain high
                    standards of quality, ensuring that our solutions meet the
                    highest industry benchmarks and exceed client expectations.
                  </span>
                </div>
                <div
                  className={
                    theme.dark ? "col-lg-4 bg-d3" : "col-lg-4 bg-b3 text-light"
                  }
                >
                  <h1 className="text-lg text-uppercase">03.</h1>
                  <h3>Collaboration</h3>
                  <span>
                    We value collaboration and believe that the best outcomes
                    are achieved through strong partnerships. We work closely
                    with our clients, understanding their unique challenges and
                    goals, and collaborate effectively to deliver solutions that
                    drive their success.
                  </span>
                </div>
              </div>
            </section>
            <section
              className={theme.dark ? "dark dark-5 p-b-0" : "light p-b-0"}
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-5"> </div>
                  <div className="col-lg-7">
                    <div className="heading-text heading-section mt-5">
                      <h1>History</h1>
                      <p>
                        TechImpace has a rich history of technological
                        innovation and customer-centric solutions that spans
                        over two decades. Founded in 2008 by Paritosh Bag, a
                        visionary technologist, the company started as a small
                        IT consulting firm, assisting businesses with their
                        technology needs.
                      </p>
                      <p>
                        In its early years, TechImpace quickly gained
                        recognition for its expertise in software development
                        and IT consulting services. The company's commitment to
                        delivering excellence and providing customized solutions
                        helped it establish a strong reputation in the market.
                        As the demand for advanced technology solutions grew,
                        TechImpace expanded its service offerings to meet the
                        evolving needs of its clients all over the India.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className={theme.dark ? "dark dark-5 p-b-0" : "light p-b-0"}
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div className="heading-text heading-section mt-5">
                      <p>
                        Over the years, TechImpace has continually invested in
                        research and development, staying at the forefront of
                        emerging technologies. The company embraced the digital
                        transformation wave, incorporating cutting-edge
                        technologies such as artificial intelligence, machine
                        learning, and data analytics into its portfolio.
                      </p>
                      <p>
                        Today, TechImpace stands as a leading technology
                        solutions provider, known for its expertise in advanced
                        technologies, custom software development, cloud
                        solutions, data analytics, and user experience design.
                        The company continues to push the boundaries of
                        innovation, helping businesses thrive in the digital era
                        by providing transformative solutions that enable them
                        to stay competitive and achieve their strategic
                        objectives.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-5"> </div>
                </div>
              </div>
            </section>
          </div>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default AboutUsPage
